/* saad css */
.md-vh-100 {
    min-height: 100vh;
}
.md-vh-100:nth-child(2) {
    align-self: end;
}
.login-signup-box {
    width: 100%;
    display: flex;
    margin: 34px 0;
    /* background: #98c8ce2b; */
    background: #293a3c;
    border-radius: 25px;
    padding: 14px;
}
.img-section.slogan-section {
    padding-top: 10px;
    position: relative;
    /* display: flex; */
    border-right: 5px double #ffd351;
    /* align-items: center; */
}
.img-section.slogan-section .form-bg-img {
    position: absolute;
    bottom: 0;
    z-index: 0;
    left: 0;
    right: 0;
    width: 90%;
    opacity: 0.4;
    margin: 0 auto;
}

.slogan-section .slogan h3 {
    color: #17a2b8;
    font-family: "Rajdhani";
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;
    z-index: 1;
}
.slogan {
    z-index: 9;
}
.both-form-section {
    background: #f9f9f9;
    border-radius: 0 25px 25px 0;
    padding: 25px;
}
/* @media only screen and (max-width: 768px) {
  .md-vh-100 {
    height: auto;
  }
} */
@media only screen and (max-width: 767px) {
    .img-section.slogan-section {
        border: none;
    }
    .form-bg-img {
        display: none;
    }
    .both-form-section {
        border-radius: 25px;
    }
}
/* saad css end */

.error-message {
    color: crimson;
    font-size: 0.7rem;
    width: 100% !important;
    text-align: left;
}

/* login btns css */
body {
    padding: 2em;
}

.opaque {
    opacity: 1 !important;
}

/* Shared */
.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #fff;
}
.loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}
.loginBtn:focus {
    outline: none;
}
.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #3b55a0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #dd4b39;
}
.loginBtn--google:before {
    border-right: #bb3f30 1px solid;
    background: url("/public/images/resources/google-icon.png") 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
    background: #e74b37;
}

/* Google */
.loginBtn--register {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #17a2b8;
}
.loginBtn--register:before {
    border-right: #0b8092 1px solid;
    background: url("/public/images/resources/user-ava.png") 6px 6px no-repeat;
}
.loginBtn--register:hover,
.loginBtn--register:focus {
    background: #17a2b8;
}

/*! CSS Used from: https://datanetdemo.com/reverie/reverie/dashboard/css/main.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}
figure {
    display: block;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
figure {
    margin: 0 0 1rem;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
button,
input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button {
    text-transform: none;
}
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}
[hidden] {
    display: none !important;
}
h1 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h1 {
    font-size: 2.5rem;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-lg-12,
.col-lg-8 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 992px) {
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    img {
        page-break-inside: avoid;
    }
    p {
        orphans: 3;
        widows: 3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
}
[class^="icofont-"] {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}
.icofont-arrow-up:before {
    content: "\ea5e";
}
body {
    margin: 0;
}
img,
svg {
    vertical-align: middle;
}
img {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
}
svg:not(:root) {
    overflow: hidden;
}
figure,
p {
    margin: 0 0 20px 0;
}
* + figure,
* + p {
    margin-top: 20px;
}
h1 {
    margin: 0 0 20px 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    color: #333;
    text-transform: none;
}
* + h1 {
    margin-top: 40px;
}
h1 {
    font-size: 2.23125rem;
    line-height: 1.2;
}
@media (min-width: 960px) {
    h1 {
        font-size: 2.625rem;
    }
}
::selection {
    background: #39f;
    color: #fff;
    text-shadow: none;
}
[hidden] {
    display: none !important;
}
@media print {
    *,
    ::after,
    ::before {
        background: 0 0 !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p {
        orphans: 3;
        widows: 3;
    }
}
/*! CSS Used from: https://datanetdemo.com/reverie/reverie/dashboard/css/style.css */
body {
    font-family: "Roboto", "Segoe Ui";
}
body {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #1f273f;
    font-size: 14px;
    letter-spacing: 0.5px;
}
p {
    color: #5a6381;
    font-size: 14px;
    line-height: 26px;
    font-family: "Roboto";
    margin-top: 0;
}
h1 {
    font-family: "Roboto";
    margin: 0;
    color: #1f273f;
}
input:focus,
button:focus {
    outline: none;
}
img {
    height: auto;
    max-width: 100%;
}
.theme-layout {
    float: left;
    position: relative;
    width: 100%;
}
.gray-bg {
    float: left;
    width: 100%;
    background: #edf2f6;
}
[type="submit"],
button {
    cursor: pointer;
}
#topcontrol {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    transform: rotate(-15deg);
    transition: all 0.2s linear 0s;
    width: 40px;
    z-index: 99;
}
#topcontrol:hover {
    transform: rotate(0);
}
.logo {
    display: inline-block;
    vertical-align: super;
}
.logo > img {
    display: inline-block;
    max-width: 40px;
    vertical-align: middle;
}
.logo > span {
    /* color: #1d3554; */
    color: #dddddd;
    font-family: Rajdhani;
    font-size: 25px;
    font-weight: 700;
    padding-left: 5px;
    vertical-align: middle;
}
.page-loader {
    position: fixed;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #088dcd;
    display: block;
}
.page-loader {
    -webkit-transition: 0.6s;
    -ms-transition: 0.6s;
    -moz-transition: 0.6s;
    transition: 0.6s;
    opacity: 1;
    visibility: visible;
}
.page-loader.hidden {
    visibility: hidden;
    opacity: 0;
}
.page-loader div {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
#page-loader > span {
    color: #fff;
    font-size: 14px;
    left: 50%;
    position: absolute;
    top: 55%;
    transform: translate(-50%, -50%);
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
input[type="checkbox"] {
    border-radius: 4px;
}
svg {
    color: #088dcd;
    fill: rgba(8, 141, 205, 0.1);
    vertical-align: middle;
}
svg:hover {
    color: #045b84;
}
.bottombar {
    float: left;
    width: 100%;
    padding: 20px 15px;
    background: #acb6be;
    text-align: center;
}
.bottom-mockup {
    margin: 0;
}
.logo-up {
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 50px;
    text-align: center;
    width: 100%;
}
.login_box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 220px 20px 20px;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    margin: 0 auto 108px;
}
.login_box .box__image {
    width: 50%;
}
.form.form--login > span {
    display: inline-block;
    vertical-align: middle;
    width: 27px;
}
@media (min-width: 768px) {
    .login_box {
        min-height: 450px;
        padding: 0;
    }
    /* .box__toggle:checked + .box__image{left:365px;} */
    .box__toggle:checked + .box__image {
        left: auto;
        right: 0;
    }
}
@media (max-width: 767px) {
    .box__toggle:checked ~ .form--register {
        width: 100%;
        height: auto;
        opacity: 1;
    }
    .box__toggle:checked ~ .form--login {
        width: 0;
        height: 0;
        opacity: 0;
    }
}
.box__image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: 0 bottom;
    transition: 0.4s ease-in-out;
}
.form__title {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    vertical-align: middle;
}
@media (min-width: 768px) {
    .box__image {
        width: 300px;
        height: 100%;
        object-position: 0 0;
    }
}
.form {
    width: 100%;
    overflow: hidden;
    text-align: left;
    transition: 0.3s;
}
/* @media (min-width: 768px) {
  .form {
    width: 50%;
    padding: 0 20px;
  }
} */
.form__title {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    vertical-align: middle;
}
.form--login {
    opacity: 1;
    transition: opacity 0.5s ease;
}
/* .form--register {
  width: 0;
  height: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
} */
@media (min-width: 768px) {
    /* .form--register {
    width: 50%;
    height: auto;
    opacity: 1;
    padding-top: 20px;
  } */
}
.form__helper {
    position: relative;
    margin-bottom: 15px;
    margin-top: 20px;
}
.form__helper:last-of-type {
    margin-bottom: 0;
}
.form__label {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: inherit;
    font-size: 13px;
    transition: 0.3s;
    margin: 0;
}
.form__input {
    position: relative;
    width: 100%;
    padding: 5px 0;
    background-color: transparent;
    border-width: 0;
    border-bottom: 1px solid #eaeaea;
    outline: none;
    font-size: 15px;
}
.form__input::placeholder {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -o-opacity: 0;
    -ms-opacity: 0;
    opacity: 0;
}
.form__input:not(:placeholder-shown) + .form__label {
    bottom: 25px;
    color: #088dcd;
    font-size: 13px;
}
.form__input:focus {
    border-bottom-color: #2e8e79;
}
.form__input:focus + .form__label {
    bottom: 25px;
    color: #2e8e79;
    font-size: 13px;
}
.form__button {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    background-color: #2e8e79;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: all 0.2s linear 0s;
}
.form__button:hover {
    border-color: #088dcd;
    background: #fff;
    color: #1f273f;
}
.form__text {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}
.form__link {
    color: #088dcd;
    cursor: pointer;
}
/*! CSS Used from: https://datanetdemo.com/reverie/reverie/dashboard/css/color.css */
.form__input:focus + .form__label,
.form__link {
    color: #088dcd;
}
#topcontrol,
.form__button {
    background: #088dcd;
}
.form__input:focus {
    border-bottom-color: #088dcd;
}
/*! CSS Used from: https://datanetdemo.com/reverie/reverie/dashboard/css/responsive.css */
@media screen and (max-width: 812px) {
    p {
        font-size: 13px;
        line-height: 24px;
    }
}
/*! CSS Used keyframes */
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
/*! CSS Used fontfaces */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
